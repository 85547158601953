<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">患者アーカイブ</label>
      </CCardHeader>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template #tools>
            <CButton v-if="isProvider()" size="sm" color="warning mr-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所</CButton>
<!--            <CButton size="sm" color="warning mr-1"  variant="outline" :pressed="archiveSearchFlg"-->
<!--                     @click="toogleArchive"-->
<!--                     v-c-tooltip="{content: 'アーカイブ済みの表示を切り替える',placement:'bottom' }">アーカイブ-->
<!--            </CButton>-->
          </template>

        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <!--            <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/>-->
          <!--            <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort">-->

          <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="hospitalNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="karuteNoHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="nameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="ageHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="genderHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="doctorNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="archiveReasonIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="patientId">
            <div class="text-left">{{ row.patientId }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="hospitalName">
            <div class="text-left">{{ row.hospitalName }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="karuteNo">
            <div class="text-left">{{ row.karuteNo }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="name">
            <div class="text-left">{{ row.name }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="age">
            <div class="text-center">{{ row.age }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="gender">
            <div class="text-center">{{ row.gender }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="doctorName">
            <div class="text-left">{{ row.doctorName }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="agencyName">
            <div class="text-left">{{ row.agencyName }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="agencyBranchName">
            <div class="text-left">{{ row.agencyBranchName }}</div>
          </template>
          <template slot-scope="{ row,index }" slot="created">
            <div class="text-left">{{ row.created }}</div>
          </template>


          <template slot-scope="{ row,index }" slot="archiveReasonId">
            <div class="text-center">
              <CButton size="sm" color="success" @click="openModal(row,row.patientId,row.archiveFlg)">
                {{ row.archiveReason }}
              </CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
    <CModal
        title="アーカイブ"
        :show.sync="archiveModal">
      <div>
        <CSelect
            label="アーカイブ理由選択"
            :options="archiveReasons"
            :value="formData.archiveReason"
            @update:value="setArchiveReason"/>
      </div>
      <template #footer>
        <CButton size="sm" color="info" @click="archive(true)">解除</CButton>
        <CButton size="sm" color="info" @click="archiveModal = false">キャンセル</CButton>
        <CButton size="sm" color="danger" @click="archive()">登録</CButton>
      </template>
    </CModal>
  </div>

</template>

<script>
import axios from 'axios'
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: 'PatientManage',
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  data: () => {
    //console.log("=========PatientList",this.$data);
    return {
      searchBarPlaceHolder: "カルテID、氏名、性別、処方医、販売店、事業所、登録Noで検索",
      columns: [
        {key: "hospitalName",title: '医療機関',slot: "hospitalName",headSlot: "hospitalNameHead",resizable: true,align: 'center',cellAlign: 'right'},
        {key: "karuteNo",title: 'カルテID',slot: "karuteNo",headSlot: "karuteNoHead",resizable: true,align: 'center',cellAlign: 'right'},
        {key: "name",title: '氏名',slot: "name",headSlot: "nameHead",resizable: true,align: 'center',cellAlign: 'right'},
        {key: "age",title: '年齢',slot: "age",headSlot: "ageHead",width:60,resizable: true,align: 'center',cellAlign: 'right'},
        {key: "gender",title: '性別',slot: "gender",headSlot: "genderHead",width:60,resizable: true,align: 'center',cellAlign: 'right'},
        {key: "doctorName",title: '処方医',slot: "doctorName",headSlot: "doctorNameHead",resizable: true,align: 'center',cellAlign: 'right'},
        {key: "agencyName",title: '販売店',slot: "agencyName",headSlot: "agencyNameHead",resizable: true,align: 'center',cellAlign: 'right'},
        {key: "agencyBranchName",title: '販売店事業所',slot: "agencyBranchName",headSlot: "agencyBranchNameHead",resizable: true,align: 'center',cellAlign: 'right'},
        // {key: "after", title: '', slot: "after", resizable: true, align: 'center', cellAlign: 'right'},
        {key: 'archiveReasonId',title: 'アーカイブ理由',width:125, slot:"archiveReasonId",headSlot:"archiveReasonIdHead", align: 'center'},
        {key: "patientId",title: '登録No',width:120,slot: "patientId",headSlot: "patientIdHead",resizable: true,align: 'center',cellAlign: 'right'},
      ],
      archiveModal: false,
      formData: {
        patientId: null,
        archiveFlg: null
      },
      archiveReasons: [
        {value: "END", label: "死去"},
        {value: "MOVE", label: "転院"},
        {value: "RECOVERY", label: "回復"},
        {value: "OTHER", label: "その他"},
      ],
      allBranchFlg:false
    }
  },
  mounted() {
    if(this.isDoctor()){
      this.hiddenColumn("hospitalName");
    }

  },
  methods: {
    openModal(row,patientId, archiveFlg) {
      console.log("openModal", JSON.stringify(row));
      //権限がないものは何もしない
      if(!this.isAdministrator() && !this.isProvider() && !this.isDoctor()) {
        return;
      }

      if (archiveFlg) {
        this.formData.patientId = patientId;
        this.formData.archiveFlg = archiveFlg;
        this.formData.archiveReason = row.archiveReasonCode;
        this.archiveModal = true;
        // this.archive();
      } else {
        this.formData.patientId = patientId;
        this.formData.archiveFlg = archiveFlg;
        this.formData.archiveReason = "END";
        this.archiveModal = true;
      }
    },
    setArchiveReason(value, event) {
      this.formData.archiveReason = value;
    },
    archive(isRemove) {
      // if (isClose) {
      //   this.archiveModal = false;
      //   return;
      // }
      this.$swal({
        title: "確認",
        text: `アーカイブ${isRemove ? '解除' : ''}処理を行いますか？`,
        buttons: true,
        dangerMode: true,
      }).then((result) => {
        if (result) {
          if(isRemove){
            axios.post(`/api/patient/archiveRemove/${this.formData.patientId}`, {headers: {"Content-Type": "application/json"}}
            ).then(response => {
              this.goodPaging();
              this.archiveModal = false;
            }).catch(error => {
              console.log('error', error.response.data);
            }).finally(() => {
            });
          }else{
            axios.post("/api/patient/archive",
                {
                  patientId: this.formData.patientId,
                  archiveReason: this.formData.archiveReason
                }, {headers: {"Content-Type": "application/json"}}
            ).then(response => {
              this.goodPaging();
              this.archiveModal = false;
            }).catch(error => {
              console.log('error', error.response.data);
            }).finally(() => {
              console.log('finally');
            });
          }
        }
      });
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      axios.post('/api/patient/archiveSearch', {pagingForm:this.pagingParams,archiveFlg:true,allBranchFlg:this.allBranchFlg}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    },toggleAllBranch(){
      this.allBranchFlg = !this.allBranchFlg;
      this.goodPaging()
    }
  }
}
</script>
